<template>
  <div>
    <div v-if="!dataPocillo.parametros_interpretacion">{{ $t('analisis.vacio') }}</div>
    <div v-else>
      <v-list>
        <v-list-group
            :value="collapseGroup"
            v-bind:class="{'hidden-activator': no_action_collapse}"
        >
          <!--:prepend-icon="icon_pocillo"-->
          <template v-slot:activator>
            <v-icon v-show="!no_action_collapse" :class="color_icon_pocillo" class="mr-2">{{ icon_pocillo }}</v-icon>
            <v-list-item-content v-show="!no_action_collapse">
              <v-list-item-title :class="color_title" v-html="title_pocillo">
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item-group>
            <div v-for="(param_result, index) in parametros_resultados" :key="index">
              <v-tooltip left :disabled="param_result.codigoParam == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item :inactive="true" v-bind="attrs" v-on="on" >
                    <v-list-item-content>
                      <v-row v-if="!software_food">
                        <v-col cols="12" sm="12">
                          <v-icon v-if="!param_result.exist" aria-hidden="false" class="c-orange" size="default">
                            mdi-alert
                          </v-icon>
                          <v-icon v-if="no_action_collapse" :class="color_icon_pocillo" class="mr-2">{{ icon_pocillo }}</v-icon>
                          <span :class="getColorParam(param_result)"
                                v-html="`${getParametroName(param_result.parametro_name)}${getResultParamTxt(param_result)}`"
                          >
                        </span>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <template v-if="!boolEditResults">
                          <v-col cols="12" sm="12" >
                            <span :class="getColorParam(param_result)" v-html="`${getParametroName(param_result.parametro_name)}${getResultParamTxt(param_result)}`" ></span>
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col cols="6" sm="6">
                            <span :class="getColorParam(param_result)">{{ getParametroName(param_result.parametro_name) }}</span>
                          </v-col>
                          <v-col cols="6" sm="6" v-if="dataPocillo.param_create == 1">
                            <v-select
                                :items="select_results_option"
                                :disabled="false"
                                v-model="param_result.resultado"
                                item-text="resultado"
                                item-value="valor"
                                append-icon="mdi-chevron-down"
                                outlined
                                @change="changeResultado(param_result, index)"
                                dense>
                            </v-select>
                          </v-col>
                          <v-col cols="6" sm="6" v-else>
                            <span :class="getColorParam(param_result)"
                                  v-html="`${getResultParamTxt(param_result)}`" ></span>
                          </v-col>
                        </template>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>{{ getCodeSoftwareParam(param_result.codigoParam) }}</span>
              </v-tooltip>
            </div>
          </v-list-item-group>
      </v-list-group>
      </v-list>
      <v-banner
          single-line v-if="no_exist_param"
      >
        <v-icon
            slot="icon"
            color="warning"
            size="20"
        >
          mdi-alert
        </v-icon>
        {{ $t('analisis.results_parametros_no_registrados') }}
      </v-banner>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import { mapState, mapActions } from 'vuex';
import i18n from '../../plugins/il8n';

export default {
  props: {
    dataPocillo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      parametros_resultados: [],
      temp_resultados: [],
      temp_data_pocillo: {},
      select_results_option: [],
      tipo_resultado: 0,
      software_food: false,
      outlier: null,
      clase: null,
      coordenadaPocillo: null,
      icon_pocillo: undefined,
      color_title: 'c-black',
      title_pocillo: '-',
      ensayo_name: '-',
      no_exist_param: null,
      codigos_software_ensayo: [],
      color_icon_pocillo: 'c-black',
      probabilitiesResults: ['High_probability', 'Medium_probability', 'Low_probability'],
      highProbabilityResult: ['High_probability'],
      no_action_collapse: false,
      collapseGroup: false,
    };
  },
  computed: {
    ...mapState('storeInterpretacion', [
      'equipos_laboratorio', 'isLab', 'archivos_requeridos', 'pocillos_interpretacion',
      'pocillos_bitacora', 'niveles_muestras', 'medios_ensayos', 'ensayos_software',
      'matrices', 'tipo_deteriorantes', 'controles', 'programa_id', 'tipoInterpretacion',
      'equipoLaboratorioSelected', 'bitacora_id', 'parametros_ensayos', 'boolEditResults', 'infoUser', 'isLab',
      'codigos_software', 'cambios',
    ]),
  },
  async created() {
    // console.log({created:'created'});
    console.log({ dataPocillo: this.dataPocillo });
    this.tipo_resultado = await this.getTipoResultadoEnsayo(this.dataPocillo.grupo_ensayo_id);
    this.ensayo_name = await this.getEnsayo(this.dataPocillo.grupo_ensayo_id);
    this.software_food = [1, 2, 10, 11].includes(this.tipo_resultado);
    this.codigos_software_ensayo = await this.getCodigosSoftware(this.dataPocillo.grupo_ensayo_id);
    console.log({ tipo_resultado: this.tipo_resultado });
    console.log({ software_food: this.software_food });
    if (this.software_food) {
      this.disabledOptionsSelectResults();
    }
  },
  async mounted() {
    await this.getParametros();
    await this.getResultPocillo();
  },
  methods: {
    ...mapActions('storeInterpretacion', ['actionLoadingModule', 'actionEditado', 'actionCambios']),
    getParametroName(prefijo) {
      let parametroName = null;
      parametroName = this.parametros_ensayos.find((parametro) => parametro.ncorto === prefijo);
      parametroName = parametroName ? parametroName.nombre : prefijo;
      switch (parametroName) {
        case 'Inhibido': parametroName = i18n.t('analisis.inhibido'); break;
        case 'Vacio': case 'Vacío': parametroName = i18n.t('analisis.vacio'); break;
        case 'Negativo': parametroName = i18n.t('analisis.negativo'); break;
        case 'Error': case 'Error_Perfil': parametroName = i18n.t('analisis.repetirReaccion'); break;
        case 'Gris': parametroName = i18n.t('analisis.reaccionGris'); break;
        case 'REPETIR': parametroName = i18n.t('analisis.reproceso'); break;
        // case 'ZONA GRIS': parametroName = i18n.t('analisis.zonagris'); break;
        // case 'INHIBIDA': parametroName = i18n.t('analisis.inhibido'); break;
      }
      return parametroName;
    },
    disabledOptionsSelectResults() {
      // en ves de disabled quitar options si es cierto rol 2
      const permition_edit_results = !this.isLab || (this.infoUser && this.infoUser.rol_id !== 2 && this.isLab);
      this.select_results_option = [
        {
          id: 1, valor: true, resultado: i18n.t('analisis.positivo'), disabled: !permition_edit_results,
        },
        {
          id: 2, valor: false, resultado: i18n.t('analisis.negativo'), disabled: !permition_edit_results,
        },
        {
          id: 3, valor: 'ZONA GRIS', resultado: i18n.t('analisis.zonagris'), disabled: false/*! permition_edit_results */,
        },
        {
          id: 4, valor: 'REPETIR', resultado: i18n.t('analisis.reproceso'), disabled: false/*! permition_edit_results */,
        },
        {
          id: 5, valor: 'INHIBIDA', resultado: i18n.t('analisis.inhibido'), disabled: false/*! permition_edit_results */,
        },
      ];
    },
    async getTipoResultadoEnsayo(grupo_ensayo_id) {
      let t_resultado = 0;
      const findEnsayo = this.ensayos_software.find((ensa) => ensa.grupo_ensayo_id == grupo_ensayo_id);
      if (findEnsayo) {
        t_resultado = findEnsayo.t_resultado;
      }
      return t_resultado;
    },
    async getEnsayo(grupo_ensayo_id) {
      let ensayoTxt = '-';
      const findEnsayo = this.ensayos_software.find((ensa) => ensa.grupo_ensayo_id == grupo_ensayo_id);
      if (findEnsayo) {
        ensayoTxt = findEnsayo.name_ensayo;
      }
      return ensayoTxt;
    },
    async getParametros() {
      if (!this.dataPocillo.parametros_interpretacion) { return; }
      const params = [];
      const parametrosInterpretacion = this.dataPocillo.parametros_interpretacion;
      this.outlier = parametrosInterpretacion.outlier;
      this.clase = parametrosInterpretacion.Clase;
      this.coordenadaPocillo = parametrosInterpretacion.Pocillo;
      const keysResultado = Object.keys(parametrosInterpretacion);
      let getNumberParams = 0;
      for await (const keyResultado of keysResultado) { if (keyResultado.indexOf('Bac_') !== -1) { getNumberParams++; } }
      const clasesSoftwareNoShowResult = ['Negativo', 'Vacio', 'Vacío', 'inhibido', 'Inhibido', 'Error_Perfil', 'Error', 'Gris', 'empty_reprocess'];
      const paramsSoftwareNoShowResult = ['Negativo', 'REPETIR'];
      const paramsSoftwareNoShowResultNotFood = ['OK', 'Others low likely microorganisms', 'Fail', 'Positive'];
      const paramsSoftwareNoShowCollapse = ['Negativo', 'Fail', 'Positive'];
      for (let keyNumber = 1; keyNumber <= getNumberParams; keyNumber++) {
        const resultadoParametroSoftware = parametrosInterpretacion[`Prob_${keyNumber}`];
        const parametroSoftware = parametrosInterpretacion[`Bac_${keyNumber}`];
        const riskParam = parametrosInterpretacion[`Risk_${keyNumber}`];
        const existParam = parametrosInterpretacion[`exist_${keyNumber}`];
        const ufcParam = parametrosInterpretacion[`ufc_${keyNumber}`] && this.tipo_resultado === 10 ? parametrosInterpretacion[`ufc_${keyNumber}`] : null;
        const codigoParam = parametrosInterpretacion[`codigo_${keyNumber}`] && this.tipo_resultado === 10 ? parametrosInterpretacion[`codigo_${keyNumber}`] : 0;
        if (!existParam) {
          this.no_exist_param = true;
        }
        const showResultBoolean = this.software_food
          ? !paramsSoftwareNoShowResult.includes(parametroSoftware)
          : (!clasesSoftwareNoShowResult.includes(this.clase) || !paramsSoftwareNoShowResultNotFood.includes(parametroSoftware));

        this.no_action_collapse = !this.software_food
          ? (clasesSoftwareNoShowResult.includes(this.clase)
                || paramsSoftwareNoShowCollapse.includes(parametroSoftware)) : false;

        const showResultPercentage = !this.probabilitiesResults.includes(resultadoParametroSoftware);
        const objectParam = {
          parametro_name: parametroSoftware,
          resultado: resultadoParametroSoftware,
          show_result: showResultBoolean,
          show_result_percentage: showResultPercentage,
          color_result: null,
          risk_level: riskParam,
          key_result: `Prob_${keyNumber}`,
          exist: existParam,
          ufcParam,
          codigoParam,
        };
        if (clasesSoftwareNoShowResult.includes(this.clase) || paramsSoftwareNoShowResultNotFood.includes(parametroSoftware)) {
          objectParam.show_result = false;
        }
        params.push(objectParam);
      }
      console.log({ params });
      this.parametros_resultados = params;
      this.parametros_resultados.forEach((param) => {
        this.temp_resultados.push({
          coordinate: this.dataPocillo.coordinate,
          resultado: param.resultado,
          parametro_name: param.parametro_name,
        });
      });
      this.collapseGroup = true;
    },
    async changeResultado(parametro, index) {
      this.actionEditado(true);
      console.log({ parametro });
      this.dataPocillo.parametros_interpretacion[parametro.key_result] = parametro.resultado;
      if (this.dataPocillo.muestra_id) {
        const oldResult = this.temp_resultados[index].resultado;
        const newResult = parametro.resultado;
        const find = this.cambios.find((cambio) => cambio.muestra_id === this.dataPocillo.muestra_id
          && cambio.parametro_name === parametro.parametro_name);
        if (find) {
          if (oldResult === newResult) {
            const filter = this.cambios.filter((cambio) => !(cambio.muestra_id === this.dataPocillo.muestra_id
                && cambio.parametro_name === parametro.parametro_name));
            this.actionCambios(filter);
          } else {
            find.resultado = parametro.resultado;
          }
        } else {
          this.cambios.push({
            clase: this.clase,
            coord: this.dataPocillo.coordinate,
            muestra_id: this.dataPocillo.muestra_id,
            parametro_name: parametro.parametro_name,
            resultado_old: oldResult,
            resultado: newResult,
            ufc: parametro.ufcParam,
            codigo: parametro.codigoParam,
          });
        }
      }

      await this.getResultPocillo();
    },
    async getResultPocillo() {
      // console.log({getResultPocillo:structuredClone(this.parametros_resultados)});
      if (!this.parametros_resultados.length) { return; }
      if (!this.software_food) {
        const clasesSoftwareCumple = ['Negativo'];
        if (clasesSoftwareCumple.includes(this.clase)) {
          this.getIconPocillo('cumple');
          this.getTitlePocillo('negative');
          return;
        }
        const clasesSoftwareReprocess = ['Vacio', 'Vacío', 'inhibido', 'Inhibido', 'Error_Perfil', 'Error', 'Gris', 'empty_reprocess'];
        if (clasesSoftwareReprocess.includes(this.clase)) {
          this.getIconPocillo('reprocess');
          this.getTitlePocillo('ensayo_name');
          return;
        }
        const findHighRisk = this.parametros_resultados.some((res) => res.risk_level == 1 && res.show_result === true);
        if (findHighRisk) {
          this.getIconPocillo('no_cumple');
          this.getTitlePocillo('high_risk');
          return;
        }
        this.getIconPocillo('cumple_low_risk');
        this.getTitlePocillo('low_risk');
      } else {
        this.getTitlePocillo('ensayo_name');
        const findPositive = this.parametros_resultados.some((res) => res.resultado === true);
        if (findPositive) { this.getIconPocillo('no_cumple'); return; }
        const res_reprocess = ['ZONA GRIS', 'REPETIR'];
        const findReprocess = this.parametros_resultados.some((res) => res_reprocess.includes(res.resultado));
        if (findReprocess) { this.getIconPocillo('reprocess'); return; }
        this.getIconPocillo('cumple');
      }
      // console.log({icon: this.icon_pocillo,title: this.title_pocillo});
    },
    getIconPocillo(result) {
      this.icon_pocillo = null;
      this.color_title = 'c-black';
      this.color_icon_pocillo = 'c-black';
      switch (result) {
        case 'cumple':
          this.icon_pocillo = 'mdi-minus-circle-outline';
          this.color_icon_pocillo = 'c-green';
          break;
        case 'cumple_low_risk':
          this.icon_pocillo = 'mdi-plus-circle-outline';
          this.color_icon_pocillo = 'c-green';
          break;
        case 'no_cumple':
          this.icon_pocillo = 'mdi-plus-circle-outline';
          this.color_title = 'c-red';
          this.color_icon_pocillo = 'c-red';
          break;
        case 'reprocess':
          this.icon_pocillo = 'mdi-help-circle-outline';
          this.color_icon_pocillo = 'c-yellow';
          break;
      }
    },
    getTitlePocillo(result) {
      switch (result) {
        case 'low_risk':
          this.title_pocillo = `${this.$t('analisis.positivo')} - ${this.$t('analisis.bajo_riesgo')}`;
          break;
        case 'high_risk':
          this.title_pocillo = `${this.$t('analisis.positivo')} - ${this.$t('analisis.alto_riesgo')}`;
          break;
        case 'ensayo_name':
          this.title_pocillo = this.ensayo_name;
          break;
        case 'negative':
          this.title_pocillo = this.$t('analisis.negativo');
          break;
      }
    },
    getColorParam(param_result) {
      const classColor = 'c-black';
      if (!this.parametros_resultados.length) { return ''; }
      if (!this.software_food) {
        if (param_result.exist === false) { return 'c-orange'; }
        if (param_result.risk_level == 1) { return 'c-red'; }
      } else if (param_result.resultado === true) { return 'c-red'; }
      return classColor;
    },
    getResultParamTxt(param_result) {
      let resultTxt = '';
      if (!this.parametros_resultados.length) { return ''; }
      if (!this.software_food) {
        if (param_result.show_result === true) {
          let resultadoText = `${param_result.resultado}${(param_result.show_result_percentage ? '%' : '')}`;
          const isHighProbability = this.highProbabilityResult.includes(param_result.resultado);

          switch (resultadoText) {
            case 'High_probability': resultadoText = this.$t('analisis.High_probability'); break;
            case 'Medium_probability': resultadoText = this.$t('analisis.Medium_probability'); break;
            case 'Low_probability': resultadoText = this.$t('analisis.Low_probability'); break;
          }
          return ` - ${isHighProbability ? `<b>${resultadoText}</b>` : resultadoText}`;
        }
      } else {
        if (!param_result.show_result) {
          return '';
        }
        const unitUfc = this.dataPocillo.matriz_id ? ([12, 7].includes(this.dataPocillo.matriz_id) ? 'UFC/500ml' : 'UFC/sample') : 'UFC/sample';
        const ufcNumber = param_result.ufcParam ? (parseInt(param_result.ufcParam) > 0 ? parseInt(param_result.ufcParam) : 0) : 0;
        const ufcCalculate = ([12, 7].includes(this.dataPocillo.matriz_id) && ufcNumber > 0) ? parseFloat((ufcNumber / 500)).toFixed(2) : ufcNumber;
        const ufcSoftware = ufcCalculate > 0 ? `<span class="c-black"> ${new Intl.NumberFormat().format(ufcCalculate)} ${unitUfc}</span>` : '';
        const codigoSoftware = param_result.codigoParam;

        switch (param_result.resultado) {
          // faltan traducciones
          case true: resultTxt = ` - ${this.$t('analisis.positivo')}${ufcSoftware}`; break;
          case false: resultTxt = ` - ${this.$t('analisis.negativo')}${ufcSoftware}`; break;
          case 'REPETIR': resultTxt = ` - ${this.$t('analisis.reproceso')}`; break;
          case 'ZONA GRIS': resultTxt = ` - ${this.$t('analisis.reproceso')}`; break;
          case 'INHIBIDA': resultTxt = ` - ${this.$t('analisis.reproceso')}`; break;
        }
      }
      return resultTxt;
    },
    async getCodigosSoftware(grupo_ensayo_id) {
      const findCodigos = this.codigos_software.filter((ensa) => ensa.grupo_ensayo_id == grupo_ensayo_id);
      return findCodigos;
    },
    findCodigoSoftware(code_result, mix_id) {
      console.log({ code_result, mix_id });
      const findCodigo = this.codigos_software_ensayo.find((ensa) => parseInt(ensa.codigo_software) === parseInt(code_result) && ensa.mix_id == mix_id);
      return findCodigo;
    },
    getCodeSoftwareParam(code_result) {
      let txt_code = '';
      const { mix_id } = this.dataPocillo;
      const codeSoftware = this.findCodigoSoftware(code_result, mix_id);
      if (codeSoftware) {
        txt_code = this.$t(`analisis.${codeSoftware.key_software}`);
      }
      return txt_code;
    },
  },
};
</script>
<style lang="scss">
.v-list-item--active {
  border-left: none !important;
  background: #ffffff !important;
}
.no-active{

}

/*.mdi-minus-circle-outline{
  color: rgb(49, 240, 11) !important;
}

.mdi-plus-circle-outline{
  color: red !important;
}

.mdi-help-circle-outline{
  color: rgb(255, 187, 0) !important;
}*/

.c-red {
  color: #F00 !important;
  line-height: 15px;
}
.c-black {
  color: #000 !important;
  //line-height: 15px;
}

.c-orange{
  color: #ff6700 !important;
  line-height: 15px;
}
.c-green{
  color: rgb(49, 240, 11) !important;
}
.c-yellow{
  color: rgb(255, 187, 0) !important;
}

.hidden-activator .v-list-group__header {
  display: none !important;  /* Oculta el título y el icono del activador */
}

.hidden-activator .v-list-group__header .v-icon {
  display: none !important;  /* Oculta el icono de expansión (triángulo) */
}

.hidden-activator .v-list-group__header {
  pointer-events: none;  /* Desactiva las interacciones con el activador */
}
</style>
